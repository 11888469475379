import { useEffect, useRef, useState } from "react";
import { useApiClient } from 'Contexts/ApiClientContext';
import { Pagination } from "utils/apiClient";
import { Button, Loading, PageHeader, Pager, TextInput } from 'Components';
import { Rma } from "types/rma";
import { isEmpty } from "utils";
import { useDebounce } from 'use-debounce';
import { RmaSearchCriteria } from "types/rma";
import RmaTable from "Components/Rmas/RmaTable";
import { IoClose } from "react-icons/io5";

interface SearchCriteria {
    mageIdsearchQuery: string;
    searchQueryGeneral: string;
}

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 30;

export default function RmaList() {
    const apiClient = useApiClient();

    // Retrieve initial search parameters from sessionStorage or default values
    const initialSearchParams = (): SearchCriteria => {
        const storedParams = sessionStorage.getItem('searchParamsRma');
        return storedParams ? JSON.parse(storedParams) : {
            mageIdsearchQuery: "",
            searchQueryGeneral: "",
        };
    };

    const [searchParams, setSearchParams] = useState<SearchCriteria>(initialSearchParams);
    const [debouncedSearchParams] = useDebounce(searchParams, 300);
    const previousSearchCriteria = useRef<RmaSearchCriteria | null>(null);

    const [rmas, setSalesOrders] = useState(null as Rma[] | null)
    const [pagination, setPagination] = useState<Pagination | null>(null)

    const handleSearchParamChange = (param: keyof SearchCriteria, value: any) => {
        setSearchParams(prevState => ({
            ...prevState,
            [param]: value
        }));
    };
    const handleClearSearchParams = () => {
        sessionStorage.removeItem('searchParamsRma');
        setSearchParams(initialSearchParams);
    }

    // Store searchParams in sessionStorage whenever they change
    useEffect(() => {
        sessionStorage.setItem('searchParamsRma', JSON.stringify(debouncedSearchParams));
    }, [debouncedSearchParams]);

    useEffect(() => {
        const fetchRmas = async () => {
            try {
                const searchCriteria: RmaSearchCriteria = {
                    mageReference: isEmpty(debouncedSearchParams.mageIdsearchQuery)
                        ? null : debouncedSearchParams.mageIdsearchQuery,
                    criteria: isEmpty(debouncedSearchParams.searchQueryGeneral)
                        ? null : debouncedSearchParams.searchQueryGeneral,
                    page: pagination?.page ?? DEFAULT_PAGE,
                }
                if (searchCriteria.mageReference === previousSearchCriteria.current?.mageReference
                    && searchCriteria.criteria === previousSearchCriteria.current?.criteria
                    && searchCriteria.page === previousSearchCriteria.current?.page) {
                    // No need to fetch again if the search criteria hasn't changed
                    return;
                }
                previousSearchCriteria.current = searchCriteria

                const { data } = await apiClient.getRmas(
                    searchCriteria,
                    pagination?.page ?? DEFAULT_PAGE,
                    pagination?.pageSize ?? DEFAULT_PAGE_SIZE
                );
                if (data) {
                    setSalesOrders(data.data);
                    setPagination(data.pagination);
                }
            } catch (error) {
                console.error("Error fetching RMAs:", error);
            }
        };

        fetchRmas();
    }, [apiClient, pagination?.page, pagination?.pageSize, debouncedSearchParams]);

    const handleNextPageClick = () => {
        if (!pagination?.hasNextPage) return;
        setPagination({ ...pagination, page: pagination?.page + 1 } as Pagination);
    };
    const handlePreviousPageClick = () => {
        if (!pagination?.hasPreviousPage) return;
        setPagination({ ...pagination, page: pagination.page - 1 } as Pagination);
    };

    const handleOrderNumberChange = (newValue: string) => {
        const search = newValue.replace(/-/g, '');

        handleSearchParamChange("mageIdsearchQuery", search)
    }


    if (rmas === null) return (
        <Loading />
    );

    return (
        <>
            <div className="flex justify-between items-center w-full">
                <PageHeader title="RMAs" />
            </div>

            <div className="flex items-center mb-4 gap-x-3">
                <TextInput
                    placeHolder="Search by order ID"
                    className="w-72"
                    value={searchParams.mageIdsearchQuery}
                    onChange={(newValue) => handleOrderNumberChange(newValue)}
                    withClearButton
                />

                <TextInput
                    placeHolder="Search by return ID or email"
                    value={searchParams.searchQueryGeneral}
                    onChange={(newValue) => handleSearchParamChange("searchQueryGeneral", newValue)}
                    withClearButton
                />
                <Button type="white" className="mt-1 w-5 h-10 shadow-none" onClick={handleClearSearchParams}>
                    <IoClose className="-my-2 -mx-2" />
                </Button>
            </div>

            <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                <RmaTable rmas={rmas} />
            </div>
            {pagination && (
                <Pager
                    currentPage={pagination.page}
                    pageSize={pagination.pageSize}
                    totalItems={pagination.totalItems}
                    handleNextPageClick={handleNextPageClick}
                    handlePreviousPageClick={handlePreviousPageClick}
                />
            )}
        </>
    );
}
