import { OrderDaysEnum, Supplier, PurchaseMethodEnum } from "types/supplier";
import { useParams } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import { useApiClient } from "Contexts/ApiClientContext";
import { formatTimeWithSecondsToHoursMinutes, isValidTimeHHmmss } from "utils";
import moment from "moment";
import { InputType } from "Components/Library/Form/MultiValueInput";
import { PageHeader, Title, SectionTitle, Card, Button, MultiValueInput, TextInput, Select, Checkbox, NumberInput, Tabs, Loading } from 'Components';
import { MdEdit } from "react-icons/md";
import { useMessage, usePermissions } from "Contexts";
import { ImBin2 } from "react-icons/im";
import { IoIosAddCircle } from "react-icons/io";
import { formatToDutchDate } from "utils/dates";
import DateRangeInput, { DateRange } from "Components/Library/Form/DateRangeInput";

export const getSupplierRoutes = (id: string) => [
    { label: "Information", path: `/suppliers/${id}` },
    { label: "To purchase", path: `/suppliers/${id}/to-purchase` },
];

export default function SupplierDetail() {

    let { id } = useParams();
    const { handleError } = useMessage()
    const [supplierData, setSupplierData] = useState<Supplier | null>(null);
    const [isUpdating, setIsUpdating] = useState(false);

    const [originalMancoDeliveryEmails, setOriginalMancoDeliveryEmails] = useState<string[]>([]);
    const [showNotifyMancoDelivery, setShowNotifyMancoDelivery] = useState(false);
    const [mancoDeliveryEmails, setMancoDeliveryEmails] = useState<string[]>([]);
    const [purchaseEmailEditActive, setPurchaseEmailEditActive] = useState(false);

    const [nonProcessingDateStartInput, setNonProcessingDateStartInput] = useState<string>("");
    const [nonProcessingDateEndInput, setNonProcessingDateEndInput] = useState<string>("");

    const apiClient = useApiClient();

    const { isAllowed, Permissions } = usePermissions();

    useEffect(() => {
        const fetchSupplier = async () => {

            try {
                const { data } = await apiClient.getSupplierByCode(id ?? '');
                if (data) {
                    setSupplierData(data);
                    setOriginalMancoDeliveryEmails(data.profortoData.mancoDeliveryEmails ?? []);
                    setMancoDeliveryEmails(data.profortoData.mancoDeliveryEmails ?? []);
                    setShowNotifyMancoDelivery(!!data.profortoData.notifyPurchaseMancoEmails);
                }
            } catch (error) {
                console.error("Error fetching suppliers:", error);
            }
        };

        fetchSupplier();
    }, [apiClient, id, setOriginalMancoDeliveryEmails]);

    const onIntakeTimeChanged = (value: string) => {
        // Quick and dirty way for now; should regex the input to make sure it's a valid time
        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                intakeTimeAmsterdam: value
            }
        } as Supplier)
    };

    const handleGlnChanged = (value: string) => {
        if (isNaN(Number(value))) return;

        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                gln: !!value ? value : null
            }
        } as Supplier)
    };

    const isOrderDayChecked = (dayValue: string | OrderDaysEnum) => {
        var targetDay = parseInt(dayValue.toString(), 10) as OrderDaysEnum;
        return supplierData?.profortoData.orderDays?.includes(targetDay)
    }

    const toggleNotifyMancoDelivery = (checked: boolean) => {
        setShowNotifyMancoDelivery(checked);
        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                notifyPurchaseMancoEmails: checked,
                mancoDeliveryEmails: checked ? mancoDeliveryEmails : originalMancoDeliveryEmails
            }
        } as Supplier)
    };

    const handleMancoDeliveryEmailsChanged = (values: SetStateAction<string[]>) => {
        const checked = supplierData?.profortoData.notifyPurchaseMancoEmails ?? false;

        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                mancoDeliveryEmails: checked ? values : originalMancoDeliveryEmails
            }
        } as Supplier)
    }

    const handlePurchaseEmailChanged = (value: string) => {
        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                purchaseEmail: value
            }
        } as Supplier)
    }

    function onToggleIntakeDay(dayValue: number | string | OrderDaysEnum, checked: boolean) {
        var targetDay = parseInt(dayValue.toString(), 10) as OrderDaysEnum;

        if (checked) {
            setSupplierData({
                ...supplierData,
                profortoData: {
                    ...supplierData?.profortoData,
                    orderDays: [...supplierData?.profortoData.orderDays ?? [], targetDay].sort()
                }
            } as Supplier)
        } else {
            setSupplierData({
                ...supplierData,
                profortoData: {
                    ...supplierData?.profortoData,
                    orderDays: supplierData?.profortoData.orderDays?.filter((day) => day !== targetDay)
                }
            } as Supplier)
        }
    }

    function onPurchaseMethodChanged(newValue: String) {
        const value = Number(newValue);

        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                purchaseMethod: value
            }
        } as Supplier)
    }

    function onRtvDayChanged(newValue: string) {
        const value = !!newValue ? Number(newValue) : null;

        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                rtvDay: value
            }
        } as Supplier)
    }

    function onRtvWeekChanged(weekNumber: number, newValue: boolean) {
        if (!supplierData) return;

        const value = Number(newValue);

        const updatedRtvWeeks = [...supplierData.profortoData.rtvWeeks || []];
        if (value) {
            if (!updatedRtvWeeks.includes(weekNumber)) {
                updatedRtvWeeks.push(weekNumber);
            }
        } else {
            const index = updatedRtvWeeks.indexOf(weekNumber);
            if (index !== -1) {
                updatedRtvWeeks.splice(index, 1);
            }
        }

        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                rtvWeeks: updatedRtvWeeks
            }
        } as Supplier)
    }

    function onRtvAddPoNumberChanged(checked: boolean) {
        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData?.profortoData,
                rtvAddPoNumber: checked
            }
        } as Supplier)
    }

    function isValidPurchaseTime() {
        return supplierData?.profortoData.intakeTimeAmsterdam === null
            || (supplierData?.profortoData.intakeTimeAmsterdam && isValidTimeHHmmss(supplierData.profortoData.intakeTimeAmsterdam));
    }

    async function updateSupplier() {
        if (!supplierData) return;

        if (!isAllowed(Permissions.UPDATE_SUPPLIERS)) return;

        // Validate the input
        if (!isValidPurchaseTime()) {
            handleError?.("Invalid 'Purchase Time'");
            return;
        }

        try {
            setIsUpdating(true);

            const { data } = await apiClient.updateSupplierData(supplierData.montaCode, supplierData.profortoData);
            if (!data) return;

            // Update the state with the new data
            setSupplierData(data);
            setPurchaseEmailEditActive(false);
        }
        finally {
            setIsUpdating(false);
        }
    }

    if (!id || !supplierData) {
        return (
            <></>
        );
    }

    // Only show the enum values, not the numeric keys
    const purchaseMethodEntries = Object.entries(PurchaseMethodEnum).filter(([_key, value]) => !isNaN(Number(value))).map(([label, value]) => ({ value: value.toString(), label }))
    const orderDaysEntries = Object.entries(OrderDaysEnum).filter(([_key, value]) => !isNaN(Number(value)))

    function onIntakeTimeBlur(value: string): void {
        const formattedTime = formatTimeWithSecondsToHoursMinutes(value)
        onIntakeTimeChanged(formattedTime)
    }

    function handleRemoveNonProcessingDate(index: number) {
        if (!supplierData) return;

        const newNonProcessingDates = supplierData.profortoData.nonProcessingDates.filter((_, i) => i !== index);
        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData.profortoData,
                nonProcessingDates: newNonProcessingDates
            }
        })
    }

    function onNonProcessingDateRangeChanged(value: DateRange) {
        setNonProcessingDateStartInput(value.startDate)
        setNonProcessingDateEndInput(value.endDate)
    }

    function handleAddNonProcessingDates() {
        if (!supplierData) return;

        const startDateInput = nonProcessingDateStartInput;
        const endDateInput = nonProcessingDateEndInput;
        if (!startDateInput || !endDateInput) return;

        const startDate = new Date(startDateInput);
        const endDate = new Date(endDateInput);

        const newDates = [];
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            newDates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const newSortedNonProcessingDates = [
            ...new Set([...supplierData.profortoData.nonProcessingDates, ...newDates])
        ].sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData.profortoData,
                nonProcessingDates: newSortedNonProcessingDates
            }
        })

        setNonProcessingDateStartInput("");
        setNonProcessingDateEndInput("");
    }

    function onChangeVendorProcDays(value: number) {
        if (!supplierData) return;

        setSupplierData({
            ...supplierData,
            profortoData: {
                ...supplierData.profortoData,
                vendorProcDays: value
            }
        })
    }

    if (supplierData === null) return (
        <Loading />
    )

    return (
        <>
            <PageHeader
                title={supplierData.name}
                metaData={`Updated: ${moment(supplierData.meta.updatedAt).format('DD-MM-YYYY HH:mm')}`}
            />
            <Tabs routes={getSupplierRoutes(id)}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-8">
                    <Card>
                        <Title>General information</Title>

                        <SectionTitle>General email</SectionTitle>
                        <span className="block italic tracking-tight text-gray-800">{supplierData.email ?? 'Not available'}</span>

                        <SectionTitle>Address</SectionTitle>
                        <span className="block italic tracking-tight text-gray-800">
                            {supplierData.address.street} {supplierData.address.houseNumber}
                            {!!supplierData.address.houseNumberAddition ?? `- ${supplierData.address.houseNumberAddition}`}, {supplierData.address.city}
                        </span>
                        <span className="block italic tracking-tight text-gray-800">
                            {supplierData.address.postalCode}, {supplierData.address.countryCode}
                        </span>

                        <SectionTitle>Communication</SectionTitle>

                        <span>GLN</span>
                        <TextInput
                            className="mb-4"
                            placeHolder={supplierData.profortoData.gln?.toString() ?? "0847976000005.."}
                            onChange={handleGlnChanged}
                            value={supplierData.profortoData.gln || ""}
                        />

                        <Checkbox
                            label="Notify manco delivery"
                            checked={showNotifyMancoDelivery}
                            onChange={toggleNotifyMancoDelivery}
                        />
                        {showNotifyMancoDelivery && (
                            <MultiValueInput
                                values={mancoDeliveryEmails ?? []}
                                setValues={(e) => {
                                    setMancoDeliveryEmails(e);
                                    handleMancoDeliveryEmailsChanged(e);
                                }}
                                inputType={InputType.Email}
                            />
                        )}
                    </Card>

                    <Card>
                        <Title>Purchase details</Title>

                        <SectionTitle>Method</SectionTitle>
                        <Select
                            value={supplierData.profortoData.purchaseMethod.toString()}
                            onChange={onPurchaseMethodChanged}
                            options={purchaseMethodEntries}
                        />
                        {supplierData.profortoData.purchaseMethod === PurchaseMethodEnum.Email && (
                            <div className="my-4">
                                <SectionTitle>Email</SectionTitle>
                                {purchaseEmailEditActive ? (
                                    <TextInput
                                        placeHolder={supplierData.profortoData.purchaseEmail ?? ""}
                                        onChange={handlePurchaseEmailChanged}
                                        value={supplierData.profortoData.purchaseEmail ?? ""}
                                    />
                                ) : (
                                    <div className="flex justify-between items-center">
                                        <p className="italic tracking-tight text-gray-800">{supplierData.profortoData.purchaseEmail}</p>
                                        <MdEdit className="cursor-pointer" onClick={() => setPurchaseEmailEditActive(true)} />
                                    </div>
                                )}
                            </div>
                        )}

                        <SectionTitle>Threshold</SectionTitle>
                        <NumberInput
                            placeHolder={(supplierData.profortoData.purchaseThreshold.value || 0).toString()}
                            onChange={(value) => {
                                setSupplierData({
                                    ...supplierData,
                                    profortoData: {
                                        ...supplierData.profortoData,
                                        purchaseThreshold: {
                                            value
                                        }
                                    }
                                })
                            }}
                            min={0}
                            step={0.01}
                            prefix={"€"}
                            value={supplierData.profortoData.purchaseThreshold.value ?? 0}
                        />

                        <SectionTitle>Order days</SectionTitle>
                        {orderDaysEntries.map(([key, value]) =>
                            <Checkbox
                                key={value}
                                label={key}
                                checked={isOrderDayChecked(value) || false}
                                onChange={(checked) => onToggleIntakeDay(value, checked)}
                            />
                        )}

                        <SectionTitle>Purchase time</SectionTitle>
                        <TextInput
                            placeHolder={supplierData.profortoData.intakeTimeAmsterdam?.toString() ?? ""}
                            onChange={onIntakeTimeChanged}
                            onBlur={onIntakeTimeBlur}
                            value={formatTimeWithSecondsToHoursMinutes(supplierData.profortoData.intakeTimeAmsterdam ?? "")}
                        />

                        <SectionTitle>Vendor processing days</SectionTitle>
                        <NumberInput
                            placeHolder={supplierData.profortoData.vendorProcDays?.toString() ?? "-"}
                            onChange={onChangeVendorProcDays}
                            min={0}
                            value={supplierData.profortoData.vendorProcDays ?? 0}
                        />

                        <SectionTitle>Closing dates</SectionTitle>
                        <div className="max-h-60 overflow-y-auto">
                            {supplierData.profortoData.nonProcessingDates.map((date, index) => (
                                <div key={date} className="flex justify-between border border-b p-2 rounded-lg my-2">
                                    <span className="italic tracking-tight text-gray-800">
                                        {formatToDutchDate(date)}
                                    </span>
                                    <button
                                        className="text-red-500 hover:text-red-300"
                                        onClick={() => handleRemoveNonProcessingDate(index)}
                                    >
                                        <ImBin2 />
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-between items-center mt-2 mr-1">
                            <DateRangeInput
                                className="w-full mr-2"
                                id="addClosingDateRange"
                                onChange={onNonProcessingDateRangeChanged}
                                onEnter={handleAddNonProcessingDates}
                                value={{ startDate: nonProcessingDateStartInput, endDate: nonProcessingDateEndInput }}
                            />
                            <button
                                className="text-green-500 hover:text-green-300"
                                onClick={handleAddNonProcessingDates}
                            >
                                <IoIosAddCircle className="w-6 h-6" />
                            </button>
                        </div>
                    </Card>

                    <Card>
                        <Title>RTV</Title>

                        <SectionTitle>Options</SectionTitle>
                        <div className="flex gap-2 flex-wrap items-center">
                            <Checkbox
                                label="Add PO number to orderlines"
                                checked={supplierData.profortoData.rtvAddPoNumber ?? false}
                                onChange={onRtvAddPoNumberChanged}
                            />
                        </div>

                        <SectionTitle>Frequency</SectionTitle>
                        <div className="flex gap-2 flex-wrap">
                            <span className="w-full">Generate a new RTV on week:</span>
                            {Array.from({ length: 53 }, (_, index) => (
                                <Checkbox
                                    key={index}
                                    label={(index + 1).toString()}
                                    checked={(supplierData.profortoData.rtvWeeks && !!supplierData.profortoData.rtvWeeks.includes(index + 1)) ?? false}
                                    onChange={(value) => onRtvWeekChanged(index + 1, value)}
                                    className="w-12"
                                />
                            ))}
                        </div>
                        <div className="flex gap-2 flex-wrap items-center">
                            on a
                            <Select
                                value={supplierData.profortoData.rtvDay?.toString() ?? ""}
                                onChange={onRtvDayChanged}
                                options={[
                                    { value: "", label: "None" },
                                    { value: "1", label: "Monday" },
                                    { value: "2", label: "Tuesday" },
                                    { value: "3", label: "Wednesday" },
                                    { value: "4", label: "Thursday" },
                                    { value: "5", label: "Friday" },
                                    { value: "6", label: "Saturday" },
                                    { value: "0", label: "Sunday" },
                                ]}
                                className="!w-32"
                            />
                        </div>
                    </Card>
                </div>

                {isAllowed(Permissions.UPDATE_SUPPLIERS) && (
                    <div className="flex justify-end mt-10">
                        <Button
                            disabled={isUpdating}
                            loading={isUpdating}
                            onClick={updateSupplier}
                            type="cta"
                        >
                            Save
                        </Button>
                    </div>
                )}
            </Tabs>
        </>
    );
}
