import { IoCloseSharp } from "react-icons/io5";
import classNames from "classnames";

type Props = {
    label?: React.ReactNode;
    placeHolder?: string;
    required?: boolean;
    disabled?: boolean;
    value: string;
    onChange: (newValue: string) => void;
    onKeyPress?: (key: string) => void;
    onBlur?: (value: string) => void;
    withClearButton?: boolean
    className?: string
    maxLength?: number
}
const TextInput = ({ label, placeHolder, required, disabled, value, onChange, onKeyPress, onBlur, withClearButton, className, maxLength }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (maxLength && e.target.value.length > maxLength) return;
        onChange(e.target.value);
    }
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!onKeyPress) return;
        onKeyPress(e.key)
    }
    const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!onBlur) return;
        onBlur(e.target.value)
    }

    return (
        <div className={classNames(className)}>
            {label && (
                <label className="font-bold">{label}</label>
            )}
            <div className="relative mt-1 flex gap-2 items-center w-96 max-w-full">
                <input
                    type="text"
                    required={required}
                    disabled={disabled}
                    className={classNames(
                        "inline-block py-2 ps-3 text-sm placeholder:italic placeholder:text-slate-400 text-gray-900 border border-gray-300 w-full rounded-lg focus:ring-blue-500 focus:border-blue-500",
                        className, {
                        " bg-white": !disabled,
                        " text-slate-100": disabled
                    })}
                    placeholder={placeHolder}
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlurHandler}
                    maxLength={maxLength}
                    {...(onKeyPress && { onKeyDown })}
                />
                {withClearButton && !disabled && value.length > 0 && (
                    <button className="absolute top-1/2 right-0 -translate-y-1/2 p-2" onClick={() => onChange('')}>
                        <IoCloseSharp />
                    </button>
                )}
            </div>
        </div>
    )
}

export default TextInput
