import { useEffect, useRef, useState } from "react";
import { useApiClient } from 'Contexts/ApiClientContext';
import { Pagination } from "utils/apiClient";
import { Button, Loading, PageHeader, Pager, Select, TextInput } from 'Components';
import { DateRange, PurchaseOrder, PurchaseOrderSearchCriteria, PurchaseOrderSubmitSelectEnum } from "types/purchase-order";
import PurchaseOrderTable from "Components/PurchaseOrders/PurchaseOrderTable";
import { useDebounce } from "use-debounce";
import { isEmpty } from "utils";
import { SupplierAsOption } from "types/supplier";
import { convertPOSubmitSelectEnumToLabel } from "utils/enums";
import Toggle from "Components/Library/Form/Toggle";
import { useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import DateRangeInput from "Components/Library/Form/DateRangeInput";

interface SearchCriteria {
    number: string;
    supplierMontaCode: string;
    sku: string;
    isSubmitted: PurchaseOrderSubmitSelectEnum;
    isNeedsAttention: boolean;
    startDate: string;
    endDate: string;
}

export default function PurchaseOrderList() {
    const apiClient = useApiClient();
    const navigate = useNavigate();
    const location = useLocation();
    // Retrieve initial search parameters from sessionStorage or default values
    const initialSearchParams = (): SearchCriteria => {
        const storedParams = sessionStorage.getItem('searchParamsPurchase');
        const value = storedParams ? JSON.parse(storedParams) : {
            number: "",
            supplierMontaCode: "",
            sku: "",
            startDate: null,
            endDate: null,
            isSubmitted: PurchaseOrderSubmitSelectEnum.Any,
            isNeedsAttention: false
        };

        if (!value.isNeedsAttention)
        {
            value.IsNeedsAttention = location.search.includes('needs-attention=true');
        }

        return value;
    };

    const [searchParams, setSearchParams] = useState<SearchCriteria>(initialSearchParams);
    const [debouncedSearchParams] = useDebounce(searchParams, 300);
    const previousSearchCriteria = useRef<PurchaseOrderSearchCriteria | null>(null);

    const [purchaseOrders, setPurchaseOrders] = useState(null as PurchaseOrder[] | null)
    const [pagination, setPagination] = useState<Pagination | null>(null)
    const [supplierOptions, setSupplierOptions] = useState<SupplierAsOption[]>([]);
    const [dateRange, setDateRange] = useState<DateRange>({ startDate: '', endDate: '' });

    // Store searchParams in sessionStorage whenever they change
    useEffect(() => {
        sessionStorage.setItem('searchParamsPurchase', JSON.stringify(debouncedSearchParams));
    }, [debouncedSearchParams]);

    useEffect(() => {
        const fetchPurchaseOrders = async () => {
            try {
                const searchCriteria: PurchaseOrderSearchCriteria = {
                    number: isEmpty(debouncedSearchParams.number)
                        ? null : debouncedSearchParams.number,
                    supplierMontaCode: isEmpty(searchParams.supplierMontaCode)
                        ? null : searchParams.supplierMontaCode,
                    sku: isEmpty(debouncedSearchParams.sku)
                        ? null : debouncedSearchParams.sku,
                    startDate: debouncedSearchParams.startDate || null,
                    endDate: debouncedSearchParams.endDate || null,
                    isSubmitted: debouncedSearchParams.isSubmitted === PurchaseOrderSubmitSelectEnum.Any
                        ? null : debouncedSearchParams.isSubmitted === PurchaseOrderSubmitSelectEnum.Submitted,
                    isNeedsAttention: debouncedSearchParams.isNeedsAttention ? true : null,
                    isInboundCreated: null,
                    page: pagination?.page ?? 1,
                }
                if (searchCriteria.number === previousSearchCriteria.current?.number
                    && searchCriteria.supplierMontaCode === previousSearchCriteria.current?.supplierMontaCode
                    && searchCriteria.sku === previousSearchCriteria.current?.sku
                    && searchCriteria.startDate === previousSearchCriteria.current?.startDate
                    && searchCriteria.endDate === previousSearchCriteria.current?.endDate
                    && searchCriteria.isSubmitted === previousSearchCriteria.current?.isSubmitted
                    && searchCriteria.isNeedsAttention === previousSearchCriteria.current?.isNeedsAttention
                    && searchCriteria.page === previousSearchCriteria.current?.page) {
                    // No need to fetch again if the search criteria hasn't changed
                    return;
                }
                previousSearchCriteria.current = searchCriteria;

                const { data } = await apiClient.getPurchaseOrders(
                    searchCriteria,
                    pagination?.page ?? 1,
                    pagination?.pageSize ?? 30
                );

                if (data) {
                    setPurchaseOrders(data.data);
                    setPagination(data.pagination);
                }
            } catch (error) {
                console.error("Error fetching purchase orders:", error);
                setPurchaseOrders([])
            }
        };

        fetchPurchaseOrders();
    }, [apiClient, debouncedSearchParams.isSubmitted, debouncedSearchParams.isNeedsAttention, debouncedSearchParams.number, pagination?.page, pagination?.pageSize, searchParams.supplierMontaCode, debouncedSearchParams.sku, debouncedSearchParams.startDate, debouncedSearchParams.endDate, location.search]);

    useEffect(() => {
        const fetchSupplierOptions = async () => {
            try {
                const { data } = await apiClient.getSuppliersAsOptions();

                if (data) {
                    setSupplierOptions(data);
                }
            } catch (error) {
                console.error("Error fetching suppliers as options:", error);
            }
        };

        fetchSupplierOptions();
    }, [apiClient]);

    const handleSearchParamChange = (param: keyof SearchCriteria, value: any) => {
        if (param === "isNeedsAttention") {
            navigate({
                search: value
                    ? `${location.search ? location.search + '&' : '?'}needs-attention=true`
                    : location.search.replace('?needs-attention=true', '').replace('needs-attention=true', '')
            }, { replace: true });
        }

        setSearchParams(prevState => ({
            ...prevState,
            [param]: value
        }));
    };
    const handleClearSearchParams = () => {
        sessionStorage.removeItem('searchParamsPurchase');
        setSearchParams(initialSearchParams);
        setDateRange({ startDate: '', endDate: '' });
    }

    const handleNextPageClick = () => {
        if (!pagination?.hasNextPage) return;
        setPagination({ ...pagination, page: pagination?.page + 1 } as Pagination);
    };
    const handlePreviousPageClick = () => {
        if (!pagination?.hasPreviousPage) return;
        setPagination({ ...pagination, page: pagination.page - 1 } as Pagination);
    };

    const supplierOptionList = () => {
        const sortedList = supplierOptions
            .map(supplier => ({
                value: supplier.montaCode,
                label: supplier.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

        sortedList.unshift({ value: "", label: "All Suppliers" });

        return sortedList;
    }

    const poIsSubmittedEntrries = Object.entries(PurchaseOrderSubmitSelectEnum)
        .map(([_label, value]) => ({ value: value.toString(), label: convertPOSubmitSelectEnumToLabel(value) }));

    const handlePersoOptionChange = (newValue: string) => {
        handleSearchParamChange("isSubmitted", newValue as PurchaseOrderSubmitSelectEnum)
    };

    const handleDateFilterChanged = (range: DateRange) => {
        handleSearchParamChange("startDate", range.startDate);
        handleSearchParamChange("endDate", range.endDate);
        setDateRange(range);
    }

    if (purchaseOrders === null) return (
        <Loading />
    )

    return (
        <>
            <PageHeader title="Purchase Orders" />

            <div className="flex items-center mb-4 gap-x-3">
                <TextInput
                    placeHolder="Search by order number (exact match)"
                    className="w-72"
                    value={searchParams.number}
                    onChange={(newValue) => handleSearchParamChange("number", newValue)}
                    withClearButton
                />
                <TextInput
                    placeHolder="Search by sku"
                    className="w-60"
                    value={searchParams.sku}
                    onChange={(newValue) => handleSearchParamChange("sku", newValue)}
                    withClearButton
                />
                <Select
                    className="!w-56"
                    value={!searchParams.supplierMontaCode ? "All Suppliers" : searchParams.supplierMontaCode}
                    onChange={(newValue) => { handleSearchParamChange("supplierMontaCode", newValue) }}
                    options={supplierOptionList()}
                />
                <Select
                    className="!w-24"
                    value={searchParams.isSubmitted.toString()}
                    onChange={(newValue) => handlePersoOptionChange(newValue)}
                    options={poIsSubmittedEntrries}
                />
                <DateRangeInput
                    key={dateRange.startDate + dateRange.endDate}
                    onChange={handleDateFilterChanged}
                    type="small"
                    value={dateRange}
                    autoFillEndDate={false}
                />
                <Button type="white" className="mt-1 w-5 h-10 shadow-none" onClick={handleClearSearchParams}>
                    <IoClose className="-my-2 -mx-2" />
                </Button>
                <Toggle
                    className="ml-auto"
                    label={"Needs Attention"}
                    value={searchParams.isNeedsAttention}
                    onChange={(newValue) => handleSearchParamChange("isNeedsAttention", newValue)}
                />
            </div>

            <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                <PurchaseOrderTable purchaseOrders={purchaseOrders} />
            </div>
            {pagination && (
                <Pager
                    currentPage={pagination.page}
                    pageSize={pagination.pageSize}
                    totalItems={pagination.totalItems}
                    handleNextPageClick={handleNextPageClick}
                    handlePreviousPageClick={handlePreviousPageClick}
                />
            )}

        </>
    );
}
