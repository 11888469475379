import { useEffect } from "react";
import { Select } from "Components"
import { usePrinters } from "Contexts";

type Props = {
    filter?: string
    storageKey?: string;
    selectedPrinter: number;
    setSelectedPrinter: (printer: number) => void;
}

const PrinterSelector = ({
    storageKey,
    filter,
    selectedPrinter,
    setSelectedPrinter
}: Props) => {
    const { availablePrinters, loading: printersLoading } = usePrinters();
    const filteredPrinters = filter === undefined
        ? availablePrinters
        : availablePrinters.filter(printer =>
            printer.description?.includes(filter)
        );

    useEffect(() => {
        if (filteredPrinters.length) {
            if (!filteredPrinters.find(printer => printer.id === selectedPrinter)) {
                setSelectedPrinter(filteredPrinters[0].id)
                if (storageKey) {
                    localStorage.setItem(storageKey, `${filteredPrinters[0].id}`)
                }
            }
        }
    }, [filteredPrinters, selectedPrinter, setSelectedPrinter, storageKey])

    const handleSelectedPrinterChange = (newValue: string) => {
        const _selectedPrinter = parseInt(newValue);
        setSelectedPrinter(_selectedPrinter);
        if (storageKey) {
            localStorage.setItem(storageKey, `${_selectedPrinter}`);
        }
    }

    if (printersLoading) return (
        <div>Loading printers...</div>
    )

    if (!filteredPrinters.length) return (
        <div className="text-gray-400 leading-4">
            No printers available<br />
            <span className="text-xs leading-3">refresh your browser to retry fetching</span>
        </div>
    )

    return (
        <Select
            value={selectedPrinter?.toString() || filteredPrinters[0].id.toString()}
            onChange={handleSelectedPrinterChange}
            options={filteredPrinters.map(printer => ({ value: printer.id.toString(), label: printer.name }))}
        />
    )
}

export default PrinterSelector;
