import { Option } from "../Components/Library/Form/Select";

export const reasonOtherConstant = "other";

export const returnReasons = [{
    label: "KLANT - VERTRAGING WAREHOUSE",
    value: "#100"
}, {
    label: "KLANT - VERTRAGING CROSSDOCK",
    value: "#101"
}, {
    label: "KLANT - FOUT IN ORDER ZONDER PERSO",
    value: "#102"
}, {
    label: "KLANT - LEVERTIJD GEEN VERTRAGING",
    value: "#103"
}, {
    label: "KLANT - VERGETEN KORTINGSCODE",
    value: "#104"
}, {
    label: "KLANT - FOUT IN ORDER MET PERSO",
    value: "#106"
}, {
    label: "KLANT - NIET MEER NODIG",
    value: "#108"
}, {
    label: "KLANT - FAILED PAYMENT",
    value: "#109"
}, {
    label: "PROFORTO - KS UIT ASSORTIMENT",
    value: "#201"
}, {
    label: "PROFORTO - SC UIT ASSORTIMENT",
    value: "#202"
}, {
    label: "PROFORTO - GEEN REACTIE PROEFDRUK",
    value: "#203"
}, {
    label: "PROFORTO - OVERIGE REDENEN",
    value: "#204"
}] as Option[];
