import classNames from 'classnames';
import moment from "moment";
import { MontaOrderEvent } from "types/sales-order";
import { FaRegCalendarAlt } from "react-icons/fa";

type Props = {
    orderEvents: MontaOrderEvent[];
}

const OrderEvents = ({ orderEvents }: Props) => {

    const sortedEventsDesc = orderEvents
        .sort((a, b) => new Date(b.montaOccured).getTime() - new Date(a.montaOccured).getTime());

    return (
        <div className="p-4 md:p-5 overflow-y-auto max-h-96">

            {sortedEventsDesc.length === 0 && (
                <p className="text-gray-500">
                    No events have been recorded for this order.
                </p>
            )}

            <ol className="relative border-s border-gray-400 ms-3.5 mb-4 md:mb-5">
                {sortedEventsDesc.map((event, index) => (
                    <li key={event.eventId} className={classNames("ms-8", { 'mb-10': index !== sortedEventsDesc.length - 1 })}>
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-gray-200 rounded-full -start-3.5 ring-8 ring-white">
                            <FaRegCalendarAlt className='text-gray-500 w-3 h-3' />
                        </span>
                        <h3 className="flex items-start mb-1 text-lg font-semibold text-gray-800">
                            {event.typeCode}

                            {index === 0 && (
                                <span className="bg-primary text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">Latest</span>
                            )}
                        </h3>
                        <p className="text-gray-900 break-words whitespace-normal max-w-3xl">
                            {event.description}
                        </p>

                        <time className="block mb-3 text-sm font-normal leading-none text-gray-500">
                            {moment(event.montaOccured).format('MMM Do, YYYY. HH:mm:ss')}
                        </time>
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default OrderEvents
