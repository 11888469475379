import React, { useEffect, useRef } from 'react';
import Chart, { ChartConfiguration, ChartDataset, Point } from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import 'chartjs-adapter-luxon';
import { RateInfoOverview } from 'types/monta-rates';

interface RateChartProps {
    rateOverview: RateInfoOverview;
}

Chart.register(zoomPlugin, annotationPlugin);

const RateChart: React.FC<RateChartProps> = ({ rateOverview }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);
    var rateInfo = rateOverview.rateInfos;
    var montaOutages = rateOverview.montaOutages;

    useEffect(() => {
        if (chartRef.current && rateInfo.length > 0) {
            if (chartInstance.current) {
                chartInstance.current.destroy(); // Destroy previous chart instance
            }

            const ctx = chartRef.current.getContext('2d');
            if (!ctx) return;

            const datasets: ChartDataset<'line', (number | Point | null)[]>[] = [];

            // Organize data by rateType
            const groupedData: { [rateType: string]: { currentCount: Point[], maxRateCount: Point[] } } = {};
            rateInfo.forEach(info => {
                info.rates.forEach(rate => {
                    const point: Point = {
                        x: new Date(info.timestamp).getTime(),
                        y: rate.currentCount
                    };
                    if (!groupedData[rate.rateType]) {
                        groupedData[rate.rateType] = { currentCount: [], maxRateCount: [] };
                    }
                    groupedData[rate.rateType].currentCount.push(point);

                    // Include maxRateCount for each rateType
                    const maxPoint: Point = {
                        x: new Date(info.timestamp).getTime(),
                        y: rate.maxRateCount
                    };
                    groupedData[rate.rateType].maxRateCount.push(maxPoint);
                });
            });

            const annotations: AnnotationOptions[] = [];
            montaOutages.forEach(outage => {
                annotations.push({
                    type: 'box',
                    xMin: new Date(outage.startDateTime).getTime(),
                    xMax: new Date(outage.endDateTime).getTime(),
                    backgroundColor: 'rgba(255, 0, 0, 0.25)',
                    borderWidth: 0
                });
            });

            // Create datasets for each rateType
            Object.entries(groupedData).forEach(([rateType, data]) => {
                datasets.push({
                    label: `${rateType} (Current Count)`,
                    data: data.currentCount,
                    fill: false,
                    borderColor: getRandomColor(),
                    tension: 0.1
                });

                datasets.push({
                    label: `${rateType} (Max Rate Count)`,
                    data: data.maxRateCount,
                    fill: false,
                    borderColor: getRandomColor(),
                    borderDash: [5, 5],
                    tension: 0.1
                });
            });

            const config: ChartConfiguration<'line'> = {
                type: 'line',
                data: {
                    datasets: datasets
                },
                options: {
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'hour',
                                displayFormats: {
                                    minute: 'DD T'
                                },
                                tooltipFormat: 'DD T'
                            },
                        },
                        y: {
                            // other y-axis configurations if needed
                        }
                    },
                    plugins: {
                        zoom: {
                            pan: {
                                enabled: true,
                                mode: 'xy'
                            },
                            zoom: {
                                wheel: {
                                    enabled: true,
                                },
                                pinch: {
                                    enabled: true
                                },
                                mode: 'xy',
                            }
                        },
                        annotation: {
                            annotations: annotations
                        }
                    }
                }
            };

            chartInstance.current = new Chart(ctx, config); // Store the new chart instance
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy(); // Cleanup when component unmounts
            }
        };
    }, [montaOutages, rateInfo]);

    // Function to generate random color
    const getRandomColor = () => {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    };

    return (
        <div>
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default RateChart;
