import { Table, Tbody, Td, Th, Thead, Tr } from "Components/Library/Table";
import { PostponeInboundForecastResponse } from "types/inbound-forecast-group";

export default function PostponeResultTable({ postponeResult }: { postponeResult: PostponeInboundForecastResponse }) {
    return (
        <div className="overflow-y-auto">
            <b className="block mb-2">Postponement result:</b>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Success count</Th>
                        <Th>Failed count</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>{postponeResult.successCount}</Td>
                        <Td>{postponeResult.failedCount}</Td>
                    </Tr>
                </Tbody>
            </Table>
            {postponeResult.failedCount > 0 && (
                <div className="mt-4">
                    <b className="block text-red-900 mb-1">Failed reasons:</b>
                    <Table>
                        <Thead>
                            <Th>Line</Th>
                            <Th>Reason</Th>
                        </Thead>
                        <Tbody>
                            {Object.entries(postponeResult.failedReasons).map(([line, reason]) => (
                                <Tr key={line}>
                                    <Td>{line}</Td>
                                    <Td>{reason}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            )}
        </div>
    )
}
