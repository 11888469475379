import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useApiClient } from 'Contexts/ApiClientContext';
import moment from "moment";
import { Table, Thead, Th, Tbody, Tr, Td, Card, Loading, PageHeader, SectionTitle } from 'Components';
import { Rma } from "types/rma";
import { formatToDutchDateTime } from "utils/dates";
import { useClickableNavigate } from "utils/PathConstants";

export default function RmaDetail() {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSku,
        navigateToSalesOrder
    } = useClickableNavigate();

    let { rmaId } = useParams();
    const apiClient = useApiClient();

    const [rma, setRma] = useState<Rma | null>(null)

    useEffect(() => {
        const fetchRma = async () => {
            if (!rmaId) return;
            try {
                const { data } = await apiClient.getRmaById(rmaId);
                if (data) {
                    setRma(data);
                }
            } catch (error) {
                console.error("Error fetching RMA:", error);
            }
        };

        fetchRma();
    }, [apiClient, rmaId]);

    if (rma === null) return (
        <Loading />
    )

    return (
        <>
            <div className="flex justify-between items-center">
                <PageHeader
                    title={rma.rmaId}
                    documentTitle={`${rma.reference.mage} | RMA`}
                    metaData={`Registered: ${moment.utc(rma.registeredAt).format('DD-MM-YYYY HH:mm')}`}
                />
            </div>

            <div className="grid md:grid-cols-2">
                <Card className="w-96">
                    <span className="block mt-1">Magento Order
                        <span
                            onMouseDown={onMouseDownHandler}
                            onMouseMove={onMouseMoveHandler}
                            onClick={(e) => navigateToSalesOrder(e, rma.reference.mage)}
                            className="block text-gray-600 hover:underline cursor-pointer"
                        >
                            {rma.reference.mage}
                        </span>
                    </span>
                    {rma.reference.bol && (
                        <span className="block mt-1">Bol Order
                            <span className="block text-gray-600">{rma.reference.bol ?? "-"}</span>
                        </span>
                    )}
                    <span className="block mt-1">Webshop Order Id
                        <span className="block text-gray-600">{rma.webshopOrderId}</span>
                    </span>
                    <span className="block mt-1">Email
                        <span className="block text-gray-600 break-all">{rma.customerEmail ?? "-"}</span>
                    </span>
                </Card>
            </div>

            <SectionTitle>Returned Items</SectionTitle>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Accepted</Th>
                        <Th>Qty Forecasted</Th>
                        <Th>Qty Received</Th>
                        <Th>Name</Th>
                        <Th>Sku</Th>
                        <Th>EAN</Th>
                        <Th>Return Reason</Th>
                        <Th>Cause</Th>
                        <Th>Comment</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rma.lines.length === 0 && (
                        <Tr>
                            <Td colSpan={7}>
                                No items found
                            </Td>
                        </Tr>
                    )}
                    {rma.lines.map((line, index) => (
                        <>
                            <Tr key={index}>
                                <Td className="w-36">
                                    {line.isSellable === null
                                        ? <></>
                                            : line.isSellable
                                            ? <span className="bg-primary text-white text-sm font-medium mr-2 px-2 py-0.5 rounded ms-3">Yes</span>
                                            : <span className="bg-red-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">No</span>
                                    }
                                </Td>
                                <Td>{line.forecastedQuantity}</Td>
                                <Td>{line.returnedQuantity}</Td>
                                <Td>
                                    <span className="flex items-center">
                                        {line.product.name}
                                    </span>
                                </Td>
                                <Td>
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onMouseDown={onMouseDownHandler}
                                        onMouseMove={onMouseMoveHandler}
                                        onClick={(e) => navigateToSku(e, line.product.sku)}
                                    >
                                        {line.product.sku}
                                    </span>
                                </Td>
                                <Td>{line.product.ean ?? "-"}</Td>
                                <Td className="whitespace-pre-line">{line.returnReason ?? "-"}</Td>
                                <Td>{line.cause ?? "-"}</Td>
                                <Td>{line.comment ?? "-"}</Td>
                            </Tr>
                        </>
                    ))}
                </Tbody>
            </Table>

            <SectionTitle>Refund events</SectionTitle>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Refunded</Th>
                        <Th>Total Quantity</Th>
                        <Th>Items</Th>
                        <Th>Occured At</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rma.refundEvents.length === 0 && (
                        <Tr>
                            <Td colSpan={4}>
                                No refund events found
                            </Td>
                        </Tr>
                    )}
                    {rma.refundEvents.map((refundEvent, index) => (
                        <Tr key={index}>
                            <Td className="w-36">
                                {refundEvent.isRefunded
                                    ? <span className="bg-primary text-white text-sm font-medium mr-2 px-2 py-0.5 rounded ms-3">Yes</span>
                                    : <span className="bg-red-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">No</span>
                                }
                            </Td>
                            <Td>{refundEvent.refundItems.reduce((total, item) => total + item.returnedQuantity, 0)}</Td>
                            <Td className="whitespace-pre-line">
                                {refundEvent.refundItems.map(item =>
                                    `${(refundEvent.refundItems.length > 1 ? `${item.returnedQuantity}x ` : '')}${item.sku}`
                                ).join("\n")}
                            </Td>
                            <Td>{formatToDutchDateTime(refundEvent.occuredAt)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}
